import React from "react";
import styled from "styled-components";
import Container from "@components/Container";
import H1 from "@components/H1";

import {math} from "polished";
import SectionHeader from "@components/SectionHeader";
import Center from "@components/Center";
import {Column} from "../../components/Row";
import {Row} from "../../components/Row";

import {slideUp} from "../../config/sal";

import blog4cover from "@images/Blog 4_cover.jpg";

import Reference from "@components/Reference";
import LinkButton from "../../components/LinkButton";

const H3 = styled.h3`
  display: inline-block;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.4;
  font-family: ${({theme}) => theme.bodyFontFamily};
  color: ${({$black, theme}) => $black ? theme.black : theme.headerColor};
  margin-top: 2rem;

  &:after {
    content: '';
    display: block;
    max-width: 56.5rem;
  }
`;

const ProductDescription = styled.p`
  max-width: 800px;
  width: 100%;
  text-align: left;
  margin: 1em auto;

  @media screen and (max-width: 575px) {
    line-height: 1.8;
  }
`;

const paragraph = [
    {
        title: "透明質酸是什麼？",
        content: <ProductDescription {...slideUp}>
            透明質酸（Hyaluronic Acid，簡稱HA），又稱為玻尿酸，是一種黏多醣的成分，亦是人體自然存在的物質，
            具有高效鎖水效果、高度黏彈性與潤滑作用，能夠保持皮膚的水分，增加皮膚的彈性和光澤及促進細胞修
            復力。多存在於皮膚真皮層、關節、眼球、神經組織等部位裡。
        </ProductDescription>,
    },
    {
        title: "透明質酸有甚麼功效及好處？",
        content: <ProductDescription {...slideUp}>
            透明質酸廣泛應用於美容護膚上，包括塗抹、口服及注射，以下為透明質酸的功效：
        </ProductDescription>,
    },
]

const reasonsList = [
    {
        header: "保濕",
        description: <ProductDescription {...slideUp}>
            透明質酸是被公認是最強的保濕成分，能夠吸收並鎖住大量的水分，讓肌膚保持水潤狀態，看起來更加豐盈和有彈性。
        </ProductDescription>,
    },
    {
        header: "撫平皺紋",
        description: <ProductDescription {...slideUp}>
            可滲透或注射至皮膚深淺層中，提升皮膚的彈性，撫平皺紋，讓肌膚回復飽滿。
        </ProductDescription>,
    },
    {
        header: "改善面部輪廓",
        description: <ProductDescription {...slideUp}>
            透明質酸除了保濕外，注射亦可作填充之用，例如填充凹陷位置，提升蘋果肌、撫平法令紋，亦可改善面部輪廓，例如微調下巴等。
        </ProductDescription>,
    },
];

const lowerParagraph = [
    {
        title: "什麼是透明質酸針？",
        content: <ProductDescription {...slideUp}>
            透明質酸針是一種非手術性的美容治療，通過注射透明質酸到皮膚真皮層，從而達到為肌膚注入源源不斷的水份、填充細紋和皺紋及填充凹陷部位，塑造立體輪廓，讓您重拾年輕肌膚。
        </ProductDescription>,
    },
    {
        title: "注射透明質酸常見副作用",
        content: <ProductDescription {...slideUp}>
            注射透明質酸是一種相對安全有效的醫美療程，但仍存在一定的風險和副作用。
            <ul>
                <li>注射後，有機會出現輕微紅腫、疼痛、瘀青或異物感，反應通常在幾天內即可消退。</li>
                <li>如果注射部位未經適當消毒或使用受污染的注射器，可能會發生感染。</li>
                <li>對透明質酸或麻醉藥物過敏的人可能會出現過敏反應，症狀包括紅疹、瘙癢、蕁麻疹、呼吸困難等
                。如果不適，應及早求醫。</li>
                <li> 用透明質酸填充下巴亦有機會引起一些副作用，如果醫生誤判或技術不佳，有可能造成注射層次不
                    正確或填補過急，都可能產生硬塊，或是出現輪廓生硬不自然，甚至有機會引起血管栓塞等問題。</li>
            </ul>
            因此確保選擇有經驗和資質的醫生進行注射，避免因技術不當導致的副作用。
        </ProductDescription>,
    },
    {
        title: "注射透明質酸的注意事項",
        content: <ProductDescription {...slideUp}>
            <ul>
                <li>在接受治療前，應充分了解相關信息，並選擇正規的醫美機構和醫生進行注射。</li>
                <li>在接受治療前，應告知醫生自己的健康狀況，如有過敏、孕婦或哺乳期婦女等應謹慎注射。</li>
                <li>在接受治療之前，建議先諮詢多位醫生，比較不同醫生的經驗和技術。</li>
                <li>在接受治療後，應注意防曬和保濕，避免劇烈運動和飲酒。</li>
                <li>注射後約一星期，不要按壓或揉捏注射部位，同時避免誇張的表情。</li>
            </ul>
        </ProductDescription>
    ,
    },
    {
        title: <a href={"https://www.belotero.com.hk/about/belotero-hyaluronic-acid/"} style={{textDecorationLine: "none"}}>BELOTERO ® 無痕透明質酸系列</a>,
        content: <ProductDescription {...slideUp}>
            想打透明質酸針？瑞士製造的BELOTERO ® 透明質酸彩虹針系列是您的不二之選。它採用獨有CPM（多密度
            凝聚）技術，結合了四大特點 （凝聚度、黏稠度、彈性及可塑性）的均衡比例，提供了極佳的肌膚融合配
            方 <sup>1</sup> ，效果自然持久 <sup>#2-3</sup> ，同時不影響天然輪廓。透過施打BELOTERO ® 透明質酸，可有效消除法令紋、提升蘋
            果肌、優化下巴線條及改善輪廓比例等，令整體臉型線條更完美，讓您可即時重獲年輕緊緻肌
            。BELOTERO ® 透明質酸彩虹針通過歐盟CE及美國FDA認可<sup>*</sup>，安全可靠，並有5 種透明質酸填充劑，能因應
            臉上皮膚的厚度和不同治療部位的填充需要客制化療程。立即查看以下
            <a href={"https://www.belotero.com.hk/about/belotero-hyaluronic-acid/"}>BELOTERO ® 不同種類的透明質酸填
            充劑。</a>
        </ProductDescription>,
    },
]

const BlogFlex = styled(Row)`
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 575px) {
    gap: 20px;
  }
`;

const ProductImg = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const NoteWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Note = styled(Reference)`
  text-align: center;
  color: ${({theme}) => theme.gray700};
`;

const AlignLeftNote = styled(Note)`
  text-align: left;
`;

const ParaImg = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

export default function Blog4() {


    return (
        <Container $maxWidth="104rem">
            <H1>【透明質酸 101】到底透明質酸針是什麼？如何發揮去衰老，皺紋等功效？</H1>

            <Center>
                <ParaImg {...slideUp} src={blog4cover} alt="belotero model"/>

                <ProductDescription {...slideUp}>
                    相信大家都有聽過透明質酸，不少護膚品及保濕面膜都含有此成份，是被公認是最強的保濕成分，可有效
                    解決肌膚乾燥缺水問題，令肌膚回復水潤嫩滑。近年保濕針及透明質酸針等醫美療程更是愈來愈受歡迎。
                    但究竟透明質酸是什麼？透明質酸針又是什麼？除了保濕外還有什麼功效？注射透明質酸有沒有副作用？
                    本文將與您一同深入探討。
                </ProductDescription>
                <br/><br/>

                {
                    paragraph.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}>{item.title}</SectionHeader>
                                {item.content}
                            </>
                        )
                    })
                }
                {
                    reasonsList.map((item) => {
                        return (
                            <>
                                <ProductDescription {...slideUp}><H3>{item.header}</H3></ProductDescription>
                                <BlogFlex>
                                    <Column $sm={12}>
                                        {item.description}
                                    </Column>
                                </BlogFlex>
                            </>
                        )
                    })
                }
                {
                    lowerParagraph.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}>{item.title}</SectionHeader>
                                {item.content}
                            </>
                        )
                    })
                }
            </Center>

            <NoteWrapper>
                <AlignLeftNote>
                    References:
                    <br/>
                    # Treatment longevity depends on the BELOTERO® product used. Effect of Belotero Volume has been shown to last up to 18 months 2 .
                    Individual treatment results and longevity may vary.
                    <br/>
                    * Belotero Balance Lidocaine is approved by the US Food and Drug Administration (FDA).
                    <br/>
                    1. Tran C, et al. Dermatology. 2014;228(1):47-54.
                    <br/>
                    2. Kerscher M, et al. Clin Cosmet Investig Dermatol. 2017;10:239-47
                    <br/>
                    3. Fischer TC et al. Facial PlastSurg. 2016 Jun; 32(3): 283-8
                    <br/>
                </AlignLeftNote>
            </NoteWrapper>
        </Container>
    );
}
