import React from "react";
import styled from "styled-components";
import Container from "@components/Container";
import H1 from "@components/H1";

import {math} from "polished";
import SectionHeader from "@components/SectionHeader";
import Center from "@components/Center";
import {Column} from "../../components/Row";
import {Row} from "../../components/Row";

import {slideUp} from "../../config/sal";

import blog5cover from "@images/Blog 5_cover.jpg";

import Reference from "@components/Reference";
import LinkButton from "../../components/LinkButton";

const H3 = styled.h3`
  display: inline-block;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.4;
  font-family: ${({theme}) => theme.bodyFontFamily};
  color: ${({$black, theme}) => $black ? theme.black : theme.headerColor};
  margin-top: 2rem;

  &:after {
    content: '';
    display: block;
    max-width: 56.5rem;
  }
`;

const ProductDescription = styled.p`
  max-width: 800px;
  width: 100%;
  text-align: left;
  margin: 1em auto;

  @media screen and (max-width: 575px) {
    line-height: 1.8;
  }
`;

const paragraph = [
    {
        title: "什麼是眼下及眼周細紋？",
        content: <ProductDescription {...slideUp}>
            眼下及眼周細紋是指出現在眼睛下方、眼瞼周圍的明顯的紋路。眼下及眼周細紋可分為「動態紋」及「靜
            態紋」。動態皺紋是指在面部表情變化時出現的皺紋，例如笑起來時出現的眼尾紋。靜態皺紋是指即使在面部表情不變時也會出現的皺紋，例如眼下細紋。而靜態皺紋又細分為「表層細紋」、「淺層靜態紋
            」、「深層靜態紋」，而眼下細紋多為「表層細紋」。「表層細紋」是因肌膚缺水而形成的。
        </ProductDescription>,
    },
    {
        title: "眼下及眼周細紋的成因",
        content:
        // <ProductDescription {...slideUp}>
        //     透明質酸廣泛應用於美容護膚上，包括塗抹、口服及注射，以下為透明質酸的功效：
        // </ProductDescription>,
        <></>,
    },
]

const reasonsList = [
    {
        header: "年齡增長",
        description: <ProductDescription {...slideUp}>
            隨著年齡的增長，皮膚中的膠原蛋白和彈力蛋白會流失，令肌膚失去原有的支撐力，導致皮膚變得鬆弛，
            更容易出現皺紋。
        </ProductDescription>,
    },
    {
        header: "皮膚缺水",
        description: <ProductDescription {...slideUp}>
            眼周肌膚缺乏水分會變得乾燥，繼而失去彈性，更容易出現「表層細紋」。
        </ProductDescription>,
    },
    {
        header: "紫外線照射",
        description: <ProductDescription {...slideUp}>
            紫外線UVA可以滲透肌膚的真皮層、刺激自由基產生，破壞細胞的結構和功能，導致眼周肌膚老化，加速
            眼下細紋、眼周皺紋的形成。
        </ProductDescription>,
    },
    {
        header: "表情過於豐富",
        description: <ProductDescription {...slideUp}>
            經常做眯眼、大笑等表情會拉扯到眼周肌膚及額頭，久而久之也會形成細紋。
        </ProductDescription>,
    },
    {
        header: "生活習慣",
        description: <ProductDescription {...slideUp}>
            一些不良的生活習慣，像是熬夜、飲食不均衡、飲酒過量都很容易累積過多的自由基，對眼周肌膚造成負
            面影響。
        </ProductDescription>,
    },
];

const lowerParagraph = [
    {
        title: "7招改善及消除眼下及眼周細紋？",
        content:
        //     <ProductDescription {...slideUp}>
        //     透明質酸針是一種非手術性的美容治療，通過注射透明質酸到皮膚真皮層，從而達到為肌膚注入源源不斷的水份、填充細紋和皺紋及填充凹陷部位，塑造立體輪廓，讓您重拾年輕肌膚。
        // </ProductDescription>,
        <></>,
    },
]

const lowerReasonsList = [
    {
        header: "改善眼紋方法#1：定時敷眼膜",
        description: <ProductDescription {...slideUp}>
            眼膜可以為眼周肌膚提供水分和營養，幫助改善眼周乾燥、眼周細紋的問題。建議每星期使用 1至2 次眼膜
            。市面上有各種不同功效的眼膜，例如滋潤、美白、淡化眼周細紋等，您可根據自己的需求選擇。
        </ProductDescription>,
    },
    {
        header: "改善眼紋方法#2：按摩",
        description: <ProductDescription {...slideUp}>
            眼周按摩可以促進眼周血液循環，幫助改善眼周肌膚的彈性。方法很簡單，先塗少量眼霜，再用無名指上
            由外眼角開始，沿著眼眶骨往眼內眼角輕按，只要持之以恆，便改善及淡化眼下及眼周細紋。
        </ProductDescription>,
    },
    {
        header: "改善眼紋方法#3：確保有充足睡眠",
        description: <ProductDescription {...slideUp}>
            良好的睡眠品質也是改善眼下細紋的重要一環。睡眠不足會導致眼周循環不佳，加上長時間用眼，容易造
            成眼部浮腫和黑眼圈，進而加速眼下細紋的產生。每晚至少要有 7 至 9 小時的睡眠時間。另外，側睡會加
            強皮膚與枕頭之間的磨擦，加速細紋的發展，盡量試著平躺。
        </ProductDescription>,
    },
    {
        header: "改善眼紋方法#4：塗抹防曬",
        description: <ProductDescription {...slideUp}>
            紫外線是導致皮膚老化的主要原因之一，而眼周肌膚又特別容易脆弱。因此，在日常防曬中，應特別加強
            眼周防曬。
        </ProductDescription>,
    },
    {
        header: "改善眼紋方法#5：溫柔地卸眼妝",
        description: <ProductDescription {...slideUp}>
            任何拉扯眼睛周圍的皮膚都會加速皺紋形成，所以在卸妝時不應而大力拉扯皮膚，可以用卸妝棉蘸取卸妝
            油或卸妝水，放在眼睛上敷1分鐘，讓睫毛膏融化，繼而輕輕擦拭眼周，減少與皮膚的摩擦。
        </ProductDescription>,
    },
    {
        header: "改善眼紋方法#6：不要搓揉眼睛",
        description: <ProductDescription {...slideUp}>
            搓揉眼睛會拉扯到眼睛周圍的皮膚，如果太大力搓揉，更會刺激眼周，加速細紋產生。
        </ProductDescription>,
    },
    {
        header: "改善眼紋方法#7: 打透明質酸",
        description: <ProductDescription {...slideUp}>
            想即時改善及消除眼下及眼周細紋，最有效的方法就是進行醫美療程，如打透明質酸。注射透明質酸可即
            時為肌膚注入滿滿的水份，更可提升皮膚的彈性，撫平皺紋，令皮膚飽滿水潤。
        </ProductDescription>,
    },
];

const BlogFlex = styled(Row)`
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 575px) {
    gap: 20px;
  }
`;

const ProductImg = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const NoteWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Note = styled(Reference)`
  text-align: center;
  color: ${({theme}) => theme.gray700};
`;

const AlignLeftNote = styled(Note)`
  text-align: left;
`;

const ParaImg = styled.img`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

export default function Blog5() {


    return (
        <Container $maxWidth="104rem">
            <H1>眼下，眼周細紋超顯眼？ 教您7 招改善及消除顯老眼紋！</H1>

            <Center>
                <ParaImg {...slideUp} src={blog5cover} alt="belotero model"/>

                <ProductDescription {...slideUp}>
                    隨着年齡的增長，不少女生應該都會發現眼底出現不少皺紋，而眼周的肌膚又特別薄弱，因此眼下細紋更
                    是格外明顯。眼下細紋會使人瞬間顯老、看起來特別疲憊沒精神。究竟有沒有方法有效改善及消除眼下及
                    眼周細紋呢？本文將一一為您說明。
                </ProductDescription>
                <br/><br/>

                {
                    paragraph.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}>{item.title}</SectionHeader>
                                {item.content}
                            </>
                        )
                    })
                }
                {
                    reasonsList.map((item) => {
                        return (
                            <>
                                <ProductDescription {...slideUp}><H3>{item.header}</H3></ProductDescription>
                                <BlogFlex>
                                    <Column $sm={12}>
                                        {item.description}
                                    </Column>
                                </BlogFlex>
                            </>
                        )
                    })
                }
                {
                    lowerParagraph.map((item) => {
                        return (
                            <>
                                <SectionHeader {...slideUp}>{item.title}</SectionHeader>
                                {item.content}
                            </>
                        )
                    })
                }
                {
                    lowerReasonsList.map((item) => {
                        return (
                            <>
                                <ProductDescription {...slideUp}><H3>{item.header}</H3></ProductDescription>
                                <BlogFlex>
                                    <Column $sm={12}>
                                        {item.description}
                                    </Column>
                                </BlogFlex>
                            </>
                        )
                    })
                }

                <br/>
                <ProductDescription {...slideUp}>
                    瑞士製造的BELOTERO ® 透明質酸彩虹針系列CPM（多密度凝聚）技術，結合了四大特點 （凝聚度、黏稠度
                    、彈性及可塑性）的均衡比例，提供了極佳的肌膚融合度 <sup>1</sup> ，效果自然持久 <sup>#2-3</sup>，同時不影響天然輪廓，同時
                    通過歐盟CE及美國FDA認可*，安全可靠。而BELOTERO ® Soft更是專為針對細紋及淺層皺紋而設，更可有效
                    改善膚質。立即了解更多。
                </ProductDescription>
            </Center>

            <NoteWrapper>
                <AlignLeftNote>
                    References:
                    <br/>
                    # Treatment longevity depends on the BELOTERO® product used. Effect of Belotero Volume has been shown to last up to 18 months 2 .
                    Individual treatment results and longevity may vary.
                    <br/>
                    * Belotero Balance Lidocaine is approved by the US Food and Drug Administration (FDA).
                    <br/>
                    1. https://www.sdiamond.com/fine-lines-prevention/
                    <br/>
                    2. https://oasis-group.com.hk/blog/%E7%9C%BC%E7%B4%8B%E6%80%A5%E6%95%91-
                    %E7%9C%BC%E4%B8%8B%E7%B4%B0%E7%B4%8B%E6%80%8E%E9%BA%BC%E8%BE%A6-
                    %E4%BA%86%E8%A7%A3%E7%9C%BC%E7%B4%8B%E6%88%90%E5%9B%A0%E5%8F%8A%E6%B6%88%E9%99%A4%E8%BE%A6%
                    E6%B3%95/
                    <br/>
                    3. https://www.elle.com/tw/beauty/news/a36645644/skiiunder-eye-care0607/
                    <br/>
                </AlignLeftNote>
            </NoteWrapper>
        </Container>
    );
}
