exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-belotero-hyaluronic-acid-js": () => import("./../../../src/pages/about/belotero-hyaluronic-acid.js" /* webpackChunkName: "component---src-pages-about-belotero-hyaluronic-acid-js" */),
  "component---src-pages-about-belotero-revive-js": () => import("./../../../src/pages/about/belotero-revive.js" /* webpackChunkName: "component---src-pages-about-belotero-revive-js" */),
  "component---src-pages-about-cpm-js": () => import("./../../../src/pages/about/cpm.js" /* webpackChunkName: "component---src-pages-about-cpm-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-certified-medical-center-clinic-center-js": () => import("./../../../src/pages/certified-medical-center/clinic-center.js" /* webpackChunkName: "component---src-pages-certified-medical-center-clinic-center-js" */),
  "component---src-pages-certified-medical-center-revive-clinic-center-js": () => import("./../../../src/pages/certified-medical-center/revive-clinic-center.js" /* webpackChunkName: "component---src-pages-certified-medical-center-revive-clinic-center-js" */),
  "component---src-pages-expert-advice-blog-1-js": () => import("./../../../src/pages/expert-advice/blog1.js" /* webpackChunkName: "component---src-pages-expert-advice-blog-1-js" */),
  "component---src-pages-expert-advice-blog-2-js": () => import("./../../../src/pages/expert-advice/blog2.js" /* webpackChunkName: "component---src-pages-expert-advice-blog-2-js" */),
  "component---src-pages-expert-advice-blog-3-js": () => import("./../../../src/pages/expert-advice/blog3.js" /* webpackChunkName: "component---src-pages-expert-advice-blog-3-js" */),
  "component---src-pages-expert-advice-blog-4-js": () => import("./../../../src/pages/expert-advice/blog4.js" /* webpackChunkName: "component---src-pages-expert-advice-blog-4-js" */),
  "component---src-pages-expert-advice-blog-5-js": () => import("./../../../src/pages/expert-advice/blog5.js" /* webpackChunkName: "component---src-pages-expert-advice-blog-5-js" */),
  "component---src-pages-expert-advice-index-js": () => import("./../../../src/pages/expert-advice/index.js" /* webpackChunkName: "component---src-pages-expert-advice-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medias-choice-js": () => import("./../../../src/pages/medias-choice.js" /* webpackChunkName: "component---src-pages-medias-choice-js" */),
  "component---src-pages-treatment-result-ageing-js": () => import("./../../../src/pages/treatment-result/ageing.js" /* webpackChunkName: "component---src-pages-treatment-result-ageing-js" */),
  "component---src-pages-treatment-result-faq-js": () => import("./../../../src/pages/treatment-result/faq.js" /* webpackChunkName: "component---src-pages-treatment-result-faq-js" */),
  "component---src-pages-treatment-result-index-js": () => import("./../../../src/pages/treatment-result/index.js" /* webpackChunkName: "component---src-pages-treatment-result-index-js" */),
  "component---src-pages-treatment-result-safety-js": () => import("./../../../src/pages/treatment-result/safety.js" /* webpackChunkName: "component---src-pages-treatment-result-safety-js" */),
  "component---src-pages-treatment-result-treatment-effect-js": () => import("./../../../src/pages/treatment-result/treatment-effect.js" /* webpackChunkName: "component---src-pages-treatment-result-treatment-effect-js" */)
}

